<template>
    <div class="launchIncome-container">
        <el-dialog v-dialogDrag class="launchIncome-drawer" title="发起收款" :append-to-body="true" :visible.sync="visibleIncome" width="560px" :before-close="()=>{closeIncome()}" :close-on-click-modal="false">
            <div class="drawer-container">
                <el-form :model="ruleFormIncome" :rules="rulesIncome" ref="ruleFormIncome" label-width="140px" class="demo-ruleForm">
                    <el-form-item label="订单总额：">
                        {{ruleFormIncome.orderPrice && Number(ruleFormIncome.orderPrice).toFixed(2)}} <span class="item-YUAN"> 元</span>
                    </el-form-item>
                    <el-form-item label="应收金额：">
                        {{ruleFormIncome.uncollectedAllAmount && Number(ruleFormIncome.uncollectedAllAmount).toFixed(2)}} <span class="item-YUAN"> 元 (未收金额)</span>
                    </el-form-item>
                    <el-form-item label="订单服务时间：" prop="platId">
                        {{orderDate}} <span class="item-YUAN"> （{{days}}）天</span>
                    </el-form-item>
                    <el-form-item label="实收金额：" prop="amount">
                        <el-input class="common-input-YUAN" placeholder="请输入金额" type="number" v-model.trim="ruleFormIncome.amount" @mousewheel.native.prevent></el-input>
                    </el-form-item>
                    <el-form-item label="实收对应时间：" prop="datePicker">
                        <el-date-picker class="common-screen-input_80" @change="(e) => changeDate(e)" v-model="ruleFormIncome.datePicker" type="daterange" range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
                        <span>（{{ruleFormIncome.effectiveDays}}）天</span>
                    </el-form-item>
                    <el-form-item label="收款方式：" prop="recordType">
                        <el-select class="common-screen-input_100" v-model="ruleFormIncome.recordType" placeholder="请选择收款方式" filterable>
                            <el-option v-for="item in incomeMode" :key="item.id + ''" :label="item.name" :value="item.id + ''" ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="通知人：" prop="noticeId">
                        <el-select v-if="noticeList && noticeList.length" class="common-screen-input_100" v-model="ruleFormIncome.noticeId" placeholder="请选择通知人" multiple filterable>
                        <el-option v-for="item in noticeList" :key="item.id + ''" :label="item.nickName + ''" :value="item.id + ''"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="备注">
                        <el-input type="textarea" :autosize="{ minRows: 6, maxRows: 6 }" placeholder="请输入内容" v-model="ruleFormIncome.remark"></el-input>
                    </el-form-item>
                    <el-form-item label="">
                        <div class="orderFile-container">
                            <div class="orderFileBtn"> 上传附件 <input class="orderFile-input" type="file" @input="(e) => {incomeFile(e)}"></div>
                            上传文件及图片，大小不超过50MB
                        </div>
                        <div class="fileList-container">
                            <div v-for="(item, index) in fileList" :key="index" class="fileList-item">
                                <div class="item-name">
                                    <!-- {{item.fileName}} -->
                                    <common-picture :fileUrl="item.fileUrl" :fileType="Config.fileType.imgType.includes(item.fileUrl.split('.')[item.fileUrl.split('.').length - 1]) ? 'imgText' : 'file'" :fileName="item.fileName"></common-picture>
                                </div>
                                <div @click="() => {deleteFlie(index)}" class="deleteBtn">删除</div>
                            </div>
                        </div>
                    </el-form-item>
                    <!-- <div style="margin-left: 32px">
                        <el-checkbox v-model="checked">是否开票</el-checkbox>
                        <div class="history-btn">查看历史开票</div>
                    </div> -->
                    <el-form-item>
                        <el-checkbox @change="(e) => isBilling(e)" v-model="checked">是否开票</el-checkbox>
                        <div class="history-btn" @click="() => { visibleHistory = true }">查看历史开票</div>
                    </el-form-item>
                    <div class="introducer" v-if="checked">
                        <el-form-item label="发票类型：" prop="invoiceType">
                            <el-radio-group v-model="ruleFormIncome.invoiceType">
                                <el-radio :label="'common'">增值普票</el-radio>
                                <el-radio :label="'special'">增值专票</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="名称：" prop="invoiceName">
                            <el-input class="dialog-input" v-model="ruleFormIncome.invoiceName" placeholder="名称" maxLength="50" ></el-input>
                        </el-form-item>
                        <el-form-item label="纳税人识别号：" prop="invoiceTaxpayerIdentificationNumber">
                            <el-input class="dialog-input" v-model="ruleFormIncome.invoiceTaxpayerIdentificationNumber" placeholder="纳税人识别号" maxLength="50"></el-input>
                        </el-form-item>
                        <el-form-item label="地址、电话：" prop="invoicePhone">
                            <el-input type="textarea" :autosize="{ minRows: 6, maxRows: 6 }" placeholder="地址、电话" v-model="ruleFormIncome.invoicePhone"></el-input>
                        </el-form-item>
                        <el-form-item label="开户行及账号：" prop="invoiceAccount">
                            <el-input type="textarea" :autosize="{ minRows: 6, maxRows: 6 }" placeholder="开户行及账号" v-model="ruleFormIncome.invoiceAccount"></el-input>
                        </el-form-item>
                        <el-form-item label="收件人：" prop="transactionName">
                            <el-input class="dialog-input" v-model="ruleFormIncome.transactionName" placeholder="收件人" maxLength="50"></el-input>
                        </el-form-item>
                        <el-form-item label="收件人手机号：" prop="transactionPhone">
                            <el-input class="dialog-input" v-model="ruleFormIncome.transactionPhone" placeholder="收件人手机号" maxLength="50"></el-input>
                        </el-form-item>
                        <el-form-item label="收件地址：" prop="invoiceAddress">
                            <el-input class="dialog-input" v-model="ruleFormIncome.invoiceAddress" placeholder="收件地址" maxLength="50"></el-input>
                        </el-form-item>
                    </div>
                </el-form>
                <!-- <div style="text-align: center;margin: 48px 0">
                    <el-button :loading="btnLoading" class="common-screen-btn" @click="() => incomeOkBtn()" type="primary" >确 定</el-button>
                    <el-button class="common-screen-btn" @click="()=>{closeIncome()}">取 消</el-button>
                </div> -->
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button class="common-screen-btn"  @click="()=>{closeIncome()}">取 消</el-button>
                <el-button class="common-screen-btn" :loading="btnLoading" type="primary" @click="() => incomeOkBtn()">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog v-dialogDrag title="历史开票" :append-to-body="true" :visible.sync="visibleHistory" width="560px" :before-close="()=>{visibleHistory = false}" :close-on-click-modal="false">
            <div class="history-container">
                <div @click="() => { chooseHistory(index) }" :class="chooseItem == index ? 'historyItem itemBorder' : 'historyItem'" v-for="(item, index) in historyInfoList" :key="index">
                    <div class="historyItem-row">
                        <div class="row-company">{{item.invoiceName}}</div>
                        <div>{{item.invoiceType == 'common' ? '增值普票' : '增值专票'}}</div>
                    </div>
                    <div class="historyItem-row">
                        <div>{{item.invoiceTaxpayerIdentificationNumber}}</div>
                        <div></div>
                    </div>
                    <div class="historyItem-row">
                        <div>{{item.invoicePhone}}</div>
                        <!-- <div>增值普票</div> -->
                    </div>
                    <div class="historyItem-row">
                        <div>{{item.invoiceAccount}}</div>
                        <!-- <div>增值普票</div> -->
                    </div>
                    <div class="receivingInfo">
                        <div>{{item.transactionName}}</div>
                        <div>{{item.transactionPhone}}</div>
                        <div>{{item.invoiceAddress}}</div>
                    </div>
                    <!-- <div class="chooseBtn">
                        <div>修改</div>
                    </div> -->
                </div>
            </div>
             <span slot="footer" class="dialog-footer">
                <el-button class="common-screen-btn"  @click="()=>{ visibleHistory = false }">取 消</el-button>
                <el-button class="common-screen-btn" :loading="btnLoading" type="primary" @click="() => {confirmBtn()}">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import { mapState } from "vuex"
import { upload } from "../../../service/upload.js";
import { incomeDetail, incomeCommit, historyInvoice } from "../../../service/customer.js"
import { DateTransform, Config } from "../../../utils/index.js"
import CommonPicture from "../../../components/common/commonPicture.vue"
export default {
    props: ['visibleIncome', 'noticeList'],
    components: {
        CommonPicture
    },
    computed: {
        ...mapState(['btnLoading'])
    },
    data() {
        var checkMoney = (rule, value, callback) => {
            if(this.typemode == 'edit'){
                if (!value) {
                  return callback(new Error('实收金额不能为空'));
                } else if(value< 0.01&&value<999999999.99){
                    return callback(new Error('实收金额不能小于0.01'));
                }else{
                    
                    if (value > (parseFloat(this.amount) + this.uncollectedAllAmount)) {
                        callback(new Error('还可发起收款'+(parseFloat(this.amount) + this.uncollectedAllAmount)+'元'));
                    } else {
                      callback();
                      }
                }
                
            }else{
                if (!value) {
                  return callback(new Error('实收金额不能为空'));
                } else if(value< 0.01&&value<999999999.99){
                    return callback(new Error('实收金额不能小于0.01'));
                }
                else{
                    if (value > this.uncollectedAllAmount) {
                        callback(new Error('还可发起收款'+this.uncollectedAllAmount+'元'));
                    } else {
                      callback();
                      }
                }
            }
        
                   
        
      };
        return {
            Config,
            chooseItem: 0, // 选中的历史开票信息
            visibleHistory: false, // 历史开票
            historyInfoList: [], // 历史开票列表
            checked: false,  // 是否开票
            ruleFormIncome: {
                invoiceType: '',
                noticeId: [],
                effectiveDays: ''
            },
            rulesIncome: {
                amount: [{ required: true, message: "不能为空", trigger: "change" },
                { validator: checkMoney,type: 'number', trigger: "change" }],
                datePicker: [{ required: true, message: "不能为空", trigger: "change" }],
                recordType: [{ required: false, message: "不能为空", trigger: "change" }],
                noticeId: [{ required: true, message: "不能为空", trigger: "change" }],
                invoiceType: [{ required: true, message: "不能为空", trigger: "change" }],
                invoiceName: [{ required: true, message: "不能为空", trigger: "change" }],
                invoiceTaxpayerIdentificationNumber: [{ required: true, message: "不能为空", trigger: "change" }],
                invoicePhone: [{ required: false, message: "不能为空", trigger: "change" }],
                invoiceAccount: [{ required: false, message: "不能为空", trigger: "change" }],
                transactionName: [{ required: true, message: "不能为空", trigger: "change" }],
                transactionPhone: [{ required: true, message: "不能为空", trigger: "change" }],
                invoiceAddress: [{ required: true, message: "不能为空", trigger: "change" }],
            },
            fileList: [], // 文件列表
            days: 0,
            orderDate: '',
            incomeMode: [
                { id: 'generalAccount', name: '对公账户（总）' },
                { id: 'publicAccount', name: '对公账户（分）' },
                { id: 'taobao', name: '淘宝' },
                { id: 'tiktok', name: '抖音' },
                { id: 'pinduoduo', name: '拼多多' },
                { id: 'bankCard', name: '银行卡' },
                { id: 'alipay', name: '支付宝' },
                { id: 'wechat', name: '微信' },
                { id: 'cash', name: '现金' },
                { id: 'other', name: '其他' },
            ],
            typemode: '',
            uncollectedAllAmount: 0,
            amount: 0
        }
    },
    methods: {
        isBilling (e) { // 是否开票
            if (!e) {
                this.ruleFormIncome.invoiceName = ''
                this.ruleFormIncome.invoiceTaxpayerIdentificationNumber = ''
                this.ruleFormIncome.invoicePhone = ''
                this.ruleFormIncome.invoiceAccount = ''
                this.ruleFormIncome.transactionName = ''
                this.ruleFormIncome.transactionPhone = ''
                this.ruleFormIncome.invoiceAddress = ''
            }
        },
        confirmBtn () { // 确认选择历史开票信息
            let historyInfo = {...this.historyInfoList[this.chooseItem]}
            this.ruleFormIncome.invoiceType = historyInfo.invoiceType
            this.ruleFormIncome.invoiceName = historyInfo.invoiceName
            this.ruleFormIncome.invoiceTaxpayerIdentificationNumber = historyInfo.invoiceTaxpayerIdentificationNumber
            this.ruleFormIncome.invoicePhone = historyInfo.invoicePhone
            this.ruleFormIncome.invoiceAccount = historyInfo.invoiceAccount
            this.ruleFormIncome.transactionName = historyInfo.transactionName
            this.ruleFormIncome.transactionPhone = historyInfo.transactionPhone
            this.ruleFormIncome.invoiceAddress = historyInfo.invoiceAddress
            this.visibleHistory = false
        },
        chooseHistory (index) { // 选择历史发票信息
            this.chooseItem = index
        },
        async historyInvoice(orderNo) { // 历史开票信息列表
            let { data } = await historyInvoice({orderNo: orderNo})
            this.historyInfoList = data
        },
        async incomeOkBtn() {  // 确认收款
            this.$vuex.commit("btnLoading", true);
            this.$refs['ruleFormIncome'].validate(async (valid) => {
                if (valid) { // 发送请求
                    let ruleFormIncome = {...this.ruleFormIncome}
                    let fileList = [...this.fileList];
                    ruleFormIncome.fileUrls = fileList
                    if(ruleFormIncome.noticeId) {
                        ruleFormIncome.noticeId = ruleFormIncome.noticeId.join(',')
                    }
                    if (ruleFormIncome.datePicker && ruleFormIncome.datePicker.length) {
                        // 处理时间数据
                        ruleFormIncome.serviceStartTimeString = DateTransform(ruleFormIncome.datePicker[0]);
                        ruleFormIncome.serviceEndTimeString = DateTransform(ruleFormIncome.datePicker[1]);
                        delete ruleFormIncome.datePicker;
                    }
                    await incomeCommit(ruleFormIncome);
                    this.$emit('orderFlowList', ruleFormIncome.recordId)
                    this.$message.success("发起收款成功");
                    this.closeIncome();
                    this.$vuex.commit("btnLoading", false);
                } else {
                    console.log("error submit!!");
                    this.$vuex.commit("btnLoading", false);
                    return false
                }
            });
            
            
            // 
        },
        changeDate (e) {  // 实际收款时间
            let ruleFormIncome = {...this.ruleFormIncome}
            if(e) {
                this.incomeDetail(ruleFormIncome.recordId, DateTransform(e[0]), DateTransform(e[1]))
            } else {
                this.incomeDetail(ruleFormIncome.recordId,)
            }
            
        },
        async incomeDetail (id, startDate, endDate,e) { // 收款明细
            let params = {}
            if(startDate && endDate) {
                params = { orderId: id, startDate: startDate, endDate: endDate }
            } else {
                params = { orderId: id }
            }
            let resData = (await incomeDetail(params)).data
            if(e == 'click'){
            //     this.ruleFormIncome.orderPrice = resData.orderPrice
            // this.ruleFormIncome.uncollectedAllAmount = resData.uncollectedAmount
            // this.ruleFormIncome.effectiveDays = resData.serviceDays
            this.uncollectedAllAmount = resData.uncollectedAllAmount //lingcun
            }else{
            this.fileList = []
            this.days = resData.days
            this.orderDate = resData.startDate + '~' + resData.endDate
            this.ruleFormIncome.recordId = id
            this.ruleFormIncome.orderPrice = resData.orderPrice
            this.ruleFormIncome.uncollectedAllAmount = resData.uncollectedAmount
            this.ruleFormIncome.effectiveDays = resData.serviceDays
            this.uncollectedAllAmount = resData.uncollectedAllAmount //lingcun
            }
            
        },
        async incomeFile (e) { // 上传文件
            let fileList = [...this.fileList]
            let file = e.target.files[0];
            let fileName = file.name;
            let fileType
            let format = file.name.split(".")[file.name.split(".").length - 1];
            if (file.size / 1024 > 1024 * 50) {
                e.target.value = "";
                return this.$message.error('文件大小不能超过50M')
            }
            if (!Config.fileType.PWEIType.includes(format)) {
                e.target.value = "";
                return this.$message.error(`只能上传 ${Config.fileMessage.PWEIType} 格式的文件`)
            }
            if ( format == "bmp" || format == "jpg" || format == "png" || format == "gif" || format == "jpeg" ) {
                fileType = 'img'
            } else {
                fileType = 'file'
            }
            this.$vuex.commit("btnLoading", true);
            let resData = (await upload({ file: file })).data;
            this.$vuex.commit("btnLoading", false);
            fileList.push({fileName: fileName, fileUrl: resData.filename, fileType: fileType})
            this.fileList = fileList
            e.target.value = ''
        },
        deleteFlie (i) {  //  删除文件
            let fileList = [...this.fileList]
            fileList = fileList.filter((item,index)=>{
                return i != index
            })
            this.fileList = fileList
        },
        getFormData(id, type, editData, orderNo) {
            this.historyInvoice(orderNo)
            if ( type && type == 'edit') {
                this.checked = false
                this.days = editData.orderDays
                this.orderDate = editData.orderStartDate + '~' + editData.orderEndDate
                this.fileList = [...editData.fileUrls]
                this.ruleFormIncome = {
                    id: editData.id,
                    recordId: id,
                    orderPrice: editData.orderPrice,
                    uncollectedAllAmount: editData.uncollectionPrice,
                    effectiveDays: editData.effectiveDays || '0',
                    noticeId: editData.noticeId ? editData.noticeId.split(',') : [],
                    datePicker: editData.serviceStartTime && editData.serviceEndTime ? [editData.serviceStartTime, editData.serviceEndTime] : [],
                    amount: editData.collectionAmount,
                    recordType: editData.recordType,
                    remark: editData.remark,
                    invoiceType: editData.invoiceType,
                    invoiceName: editData.invoiceName,
                    invoiceTaxpayerIdentificationNumber: editData.invoiceTaxpayerIdentificationNumber,
                    invoicePhone: editData.invoicePhone,
                    invoiceAccount: editData.invoiceAccount,
                    transactionName: editData.transactionName,
                    transactionPhone: editData.transactionPhone,
                    invoiceAddress: editData.invoiceAddress,
                }
                this.checked = editData.invoiceName ? true : false
                this.amount = editData.collectionAmount
                this.typemode = "edit"
            } else {
                this.ruleFormIncome = {
                    recordId: id,
                    orderPrice: '',
                    uncollectedAllAmount: '',
                    effectiveDays: '',
                    noticeId: [],
                    datePicker: [],
                    amount: '',
                    recordType: '',
                    remark: '',
                    invoiceType: 'common',
                    invoiceName: '',
                    invoiceTaxpayerIdentificationNumber: '',
                    invoicePhone: '',
                    invoiceAccount: '',
                    transactionName: '',
                    transactionPhone: '',
                    invoiceAddress: '',
                }
                this.incomeDetail(id)
                if(this.$refs["ruleFormIncome"]) {
                    this.$refs["ruleFormIncome"].resetFields();
                }
            }
        },
        closeIncome() {
            this.$emit('closeIncome', false)
        }
    }
}
</script>
<style lang="less" scoped>
.history-container {
    .itemBorder {
        border-color: #1890ff !important;
    }
    .historyItem {
        border-radius: 4px;
        border: 1px solid #d9d9d9;
        padding: 24px;
        box-sizing: border-box;
        margin-bottom: 16px;
        cursor: pointer;
        .historyItem-row {
            width: 100%;
            display: flex;
            justify-content: space-between;
            font-size: 14px;
            line-height: 28px;
            .row-company {
                font-size: 18px;
                margin-bottom: 16px;
            }
        }
        .receivingInfo {
            border-left: 4px solid #1890ff;
            padding-left: 16px;
            margin-top: 16px;
            div {
                line-height: 28px;
            }
        }
        .chooseBtn {
            display: flex;
            flex-direction: row-reverse;
            div {
                cursor: pointer;
                color: #1890ff;
            }
        }
    }
}
/deep/.el-drawer__body {
    padding: 0 !important;
}
.launchIncome-drawer {
    .introducer {
        position: relative;
        padding: 24px 0 0;
        &::before {
            background-color: #f5f5f5;
            position: absolute;
            display: block;
            content: " ";
            height: 1px;
            width: 120%;
            left: -10%;
            top: 0;
        }
    }
    .drawer-container {
        overflow: hidden;
        padding: 0 14px 0 0;
        text-align: left;
        .history-btn {
            display: inline-block;
            color: #1890ff;
            cursor: pointer;
            margin-left: 16px;
            font-size: 14px;
        }
        .item-YUAN {
            font-size: 14px;
            color: #999;
        }
        .fileList-container {
            .fileList-item {
                display: flex;
                justify-content: space-between;
                width: 320px;
                .item-name {
                font-size: 12px;
                color: #333;
                width: 80%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                }
                .deleteBtn {
                color: #1890ff;
                cursor: pointer;
                }
            }
        }
        .orderFile-container {
            .orderFileBtn {
                width: 100px;
                height: 32px;
                line-height: 32px;
                border:  1px solid rgba(220, 223, 230, 100);
                color: #666;
                background: #fff;
                text-align: center;
                font-size: 14px;
                position: relative;
                border-radius: 4px;
                display: inline-block;
                .orderFile-input {
                    width: 100px;
                    height: 32px;
                    position: absolute;
                    left: 0;
                    top: 0;
                    opacity: 0;
                    cursor: pointer;
                    font-size: 0;
                }
            }
            
        }
    }
}
</style>