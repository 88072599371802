<template>
    <div>
        <el-dialog v-dialogDrag title="订单调价审批" :append-to-body="true" :visible.sync="editVisible" width="60%" :close-on-click-modal="false">
            <template slot="title">
               <el-tooltip class="item" effect="dark" content="【用户说明】订单已收款发起调价需经过审批" placement="bottom" enterable >
                    <span>订单调价<img src="../../../assets/images/question.png" class="tit-img"/></span> 
                </el-tooltip> 
            </template>
            <el-form :model="ruleFormEdit" :rules="ruleEditRules" ref="ruleFormEdit" class="changePrice-container" label-width="120px">
                <div class="form-left">
                    <el-form-item label="类型名称" prop="workOrderId">
                        <el-select class="common-screen-input_100" v-model="ruleFormEdit.workOrderId" @change="(val) => {workOrderTemplate(val)}" placeholder="请选择类型" filterable>
                            <el-option v-for="(item, index) in workOrderSelect" :key="index" :label="item.workTypeName" :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="审批工单名称" prop="taskName">
                        <el-input class="common-screen-input_100" v-model="ruleFormEdit.taskName" maxlength="100" type="text" placeholder="请输入审批工单名称"></el-input>
                    </el-form-item>
                    <el-form-item label="优先级">
                        <el-select class="common-screen-input_100" v-model="ruleFormEdit.priority" placeholder="请选择类型">
                            <el-option label="低" :value="1"></el-option>
                            <el-option label="中" :value="2"></el-option>
                            <el-option label="高" :value="3"></el-option>
                            <el-option label="紧急" :value="4"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="计划开始时间" prop="planStartTime">
                        <el-date-picker class="common-screen-input_100" v-model="ruleFormEdit.planStartTime" @change="(val) => {dateTime(val, 'start')}" value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="请选择计划开始时间" :picker-options="startPickerOptions"></el-date-picker>
                    </el-form-item>
                    <el-form-item label="计划完成时间" prop="planEndTime">
                        <el-date-picker class="common-screen-input_100" v-model="ruleFormEdit.planEndTime" @change="(val) => {dateTime(val, 'end')}" value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="请选择计划完成时间" :picker-options="endPickerOptions"></el-date-picker>
                    </el-form-item>
                </div>
                <div class="form-right">
                    <el-form-item label="描述">
                        <el-input class="common-screen-input_100" type="textarea" :autosize="{ minRows: 6, maxRows: 6 }" placeholder="请输入内容" v-model="ruleFormEdit.remark" ></el-input>
                    </el-form-item>
                    <el-form-item label="附件：">
                        <div class="common-uploadBnList-container">
                        <div class="fileBtn-container">
                            <div class="fileBtn">
                                上传附件
                                <input class="file-input" type="file" @input="(e) => {uploadFile(e)}"/>
                            </div>
                                上传文件及图片，大小不超过50MB
                        </div>
                        <br />
                        <div class="fileList-container">
                            <div v-for="(item, index) in fileList" :key="index" class="fileList-item">
                                <div class="item-name">
                                    <common-picture :fileUrl="item.filedUrl" :fileType="item.filedType" :fileName="item.filedName"></common-picture>
                                </div>
                                <div @click="() => {deleteFlie(index)}" class="deleteBtn">删除</div>
                            </div>
                        </div>
                        </div>
                    </el-form-item>
                </div>
                <div class="grey-line"></div>
                <div class="form-title">关联信息</div>
                <div class="form-left">
                    <el-form-item label="订单编号">
                        <el-input class="common-screen-input_100" v-model="ruleFormEdit.changePriceDTO.orderNo" disabled type="text"></el-input>
                    </el-form-item>
                    <el-form-item label="订单应收金额">
                        <el-input class="common-input-YUAN" v-model="ruleFormEdit.changePriceDTO.orderMoneny" disabled type="text"></el-input>
                    </el-form-item>
                    <el-form-item label="改价类型" prop="changePriceDTO.type">
                        <el-radio-group v-model="ruleFormEdit.changePriceDTO.type">
                            <el-radio :label="0">加班费</el-radio>
                            <el-radio :label="1">咨询量差价</el-radio>
                            <el-radio :label="2">增减坐席/人力</el-radio>
                            <el-radio :label="3">服务扣款</el-radio>
                            <el-radio :label="5">服务对接群</el-radio>
                            <el-radio :label="6">增减服务时长</el-radio>
                            <el-radio :label="4">其他</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <div class="overtime-container">
                        <el-form-item label="店铺" prop="shopIds">
                            <el-select v-if="shopSelect" class="common-screen-input_100" v-model="ruleFormEdit.changePriceDTO.shopIds" placeholder="请选择店铺" multiple filterable>
                                <el-option v-for="item in shopSelect" :key="item.id + ''" :label="item.shopName + ''" :value="item.id + ''"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="时间段" prop="datePicker">
                            <el-date-picker class="common-screen-input_100" v-model="ruleFormEdit.changePriceDTO.datePicker"  value-format="yyyy-MM-dd" type="daterange" range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptionsPrice"></el-date-picker>
                        </el-form-item>
                        <el-form-item v-if="ruleFormEdit.changePriceDTO.type == 0" label="子账号数量" prop="changePriceDTO.agreeSubNum">
                            <el-input class="common-screen-input_100" v-model="ruleFormEdit.changePriceDTO.agreeSubNum" type="text" @mousewheel.native.prevent maxLength="50" placeholder="请输入子账号数量“不要默认为“0“"></el-input>
                        </el-form-item>
                    </div>
                    <el-form-item label="调整金额" prop="changePriceDTO.changeMoneny" :rules="[{ required: true, message: '请填写金额', trigger: 'change' }, { validator: (rule, value, callback) => { ruleShopMin(value, callback) }, trigger: 'change' }]">
                        <el-input class="common-input-YUAN"  v-model="ruleFormEdit.changePriceDTO.changeMoneny" type="text" @mousewheel.native.prevent maxLength="50" @input="(e) => {changePrice(e)}" placeholder="请输入调价金额"></el-input>
                    </el-form-item>
                    <el-form-item label="改价结果">
                        <el-input class="common-input-YUAN"  v-model="ruleFormEdit.changePriceDTO.result" disabled type="text" placeholder="系统计算项"></el-input>
                    </el-form-item>
                </div>
                <div class="form-right">
                    <el-form-item label="改价说明">
                        <el-input class="common-screen-input_100" type="textarea" :autosize="{ minRows: 6, maxRows: 6 }" placeholder="请输入内容" v-model="ruleFormEdit.changePriceDTO.remark" ></el-input>
                    </el-form-item>
                </div>
                <div class="grey-line"></div>
                <div class="form-title">流程设置</div>
                <div v-if="!ruleFormEdit.workOrderId" style="margin: 16px 0 0 32px;color: #696969">请选择工单类型模板</div>
                <div v-else class="workOrder-process-container">
                    <el-timeline>
                        <el-timeline-item>
                            <template slot="dot">
                            <div class="timelineItem-dot">1</div>
                            </template>
                            <div class="process-title">创建工单</div>
                        </el-timeline-item>
                        <el-timeline-item v-for="(item, index) in ruleFormEdit.flowList" :key="index">
                            <template slot="dot">
                                <div class="timelineItem-dot">{{ index + 2 }}</div>
                            </template>
                            <div>
                                <div class="common-multiple-container" :style="item.status == 0 ? '' : 'background: #efefef'">
                                    <div class="label">审批人</div>
                                    <div class="content">
                                        <div v-for="(itemTwo, indexTwo) in item.noticeList" :key="indexTwo" class="multiple-item">{{ itemTwo.name }}</div>
                                    </div>
                                    <div class="multiple-btn" v-if="item.status == 0 && !item.dealId" @click="() => {personShow(item.dealRole, index)}">选择人员</div>
                                    <div class="multiple-btn" v-else style="color: #ccc">选择人员</div>
                                </div>
                            </div>
                        </el-timeline-item>
                        <el-timeline-item>
                            <template slot="dot">
                                <div class="timelineItem-dot">{{ ruleFormEdit.flowList.length + 1 }}</div>
                            </template>
                            <div class="common-multiple-container">
                                <div class="label">抄送人</div>
                                <div class="content">
                                    <div v-for="(item, index) in ruleFormEdit.sendList" :key="index" class="multiple-item">{{ item.name }}</div>
                                </div>
                                <div class="multiple-btn" v-if="!ruleFormEdit.sendId"  @click="() => {personShow(ruleFormEdit.sendRole, 'sendRole')}">选择人员</div>
                                <div class="multiple-btn" v-else style="color: #ccc">选择人员</div>
                            </div>
                        </el-timeline-item>
                        <el-timeline-item>
                            <template slot="dot">
                                <div class="timelineItem-dot">{{ ruleFormEdit.flowList.length + 3 }}</div>
                            </template>
                            <div class="process-title">完成工单</div>
                        </el-timeline-item>
                    </el-timeline>
                </div>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button class="common-screen-btn" @click="editVisible = false">取 消</el-button>
                <el-button class="common-screen-btn" :loading="btnLoading" type="primary" @click="() => {launchTask('ruleFormEdit')}">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog v-dialogDrag title="选择人员" :append-to-body="true" :visible.sync="personVisible" width="630px" :before-close="() => {personVisible = false} " :close-on-click-modal="false">
            <div class="common-chooseItem-container">
                <div v-for="(item, index) in checkedList" :key="index" class="common-choose-item">
                    <span>{{ item.name }}</span>
                    <i @click="() => {deletePerson(item)}" class="el-icon-circle-close"></i>
                </div>
            </div>
            <br /><br />
            <div class="common-person-container">
                <div class="person-title">
                <div class="left-text">组织成员</div>
                <div class="right-seach">
                    <el-input class="seach-input" placeholder="请输入内容" prefix-icon="el-icon-search" @change="(val) => seachFun(val)" v-model="seachInfo"></el-input>
                </div>
                </div>
                <div class="person-content">
                <div class="content-left">
                    <el-tree :data="roleData" :props="defaultProps" @node-click="(val) => {departTreeFun(val)}"></el-tree>
                </div>
                <div class="content-right">
                    <div v-for="(item, index) in personData" :key="index">
                    <el-checkbox v-model="item.checked" @change="(val) => {changePerson(val, item)}">{{ item.nickName }}</el-checkbox></div>
                </div>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button class="common-screen-btn" @click="() => {personVisible = false} ">取 消</el-button>
                <el-button class="common-screen-btn" type="primary" @click="() => {noticeFun()}">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import { decimalNull, Config, DateTransform } from '../../../utils/index.js'
import { workOrderType, workOrderTemplate, getTemplateRole, launchTask } from '../../../service/workOrder.js'
import { getRoleList } from "../../../service/manage.js";
import { personList } from "../../../service/common.js";
import { upload } from "../../../service/upload.js"
import CommonPicture from "../../../components/common/commonPicture.vue";
import { mapState } from "vuex";
export default {
    props: ['shopSelect'],
    components: { CommonPicture },
    data() {
        return {
            editVisible: false,
            btnLoading: false,
            personVisible: false, // 选择人员
            orderInfo: {},
            workOrderSelect: [], // 类型下拉
            roleData: [], // 选择人员角色
            ruleFormEdit: {
                changePriceDTO: {},
                flowList: []
            },
            ruleEditRules: { // 校验规则
                workOrderId: [{ required: true, message: "请选择类型", trigger: "change" }],
                taskName: [{ required: true, message: "请填写工单名称", trigger: "change" }],
                planStartTime: [{ required: true, message: "请选择时间", trigger: "change" }],
                planEndTime: [{ required: true, message: "请选择时间", trigger: "change" }],
                changePriceDTO: {
                    type: [{ required: true, message: "请选择", trigger: "change" }],
                    datePicker: [{ required: true, message: "请选择", trigger: "change" }],
                    // changeMoneny: [{ required: true, message: "请填写金额", trigger: "change" },{ validator: ruleNegative, trigger: "change" }],
                    agreeSubNum: [{ validator: decimalNull, trigger: "change" }]
                },
            },
            fileList: [], // 描述文件
            startPickerOptions: {},
            endPickerOptions: {},
            pickerOptionsPrice: {},
            defaultProps: {
                children: "child",
                label: "name"
            },
            editIndex: '',
            personData: [],
            checkedList: [],
            seachInfo: "",
            
        }
    },
    computed: {
        ...mapState(["comId"])
    },
    mounted () {
        this.workOrderType()
    },
    methods: {
        ruleShopMin (value, callback) { // 调整金额，大于选中店铺金额
            // let ruleFormEdit = JSON.parse(JSON.stringify(this.ruleFormEdit))
            // let shopSelect = JSON.parse(JSON.stringify(this.shopSelect))
            // let shopIds = ruleFormEdit.changePriceDTO.shopIds || []
            // let shopPrice = 0
            // shopSelect.map((item) => {
            //     shopIds.map((itemTwo) => {
            //         if (item.id == itemTwo) {
            //             shopPrice += Number(item.price)
            //         }
            //     })
            // })
            if (!value && value != 0) {
                callback(new Error(`请填写金额`));
            } else if (!/^-?\d{1,9}([.]\d{1,2})?$/.test(value)) {
                callback(new Error(`只能输入数字，最多保留两位小数`));
            } else if (Number(value) < (Number(this.orderInfo.price) * -1)) {
                callback(new Error(`调价结果不能小于0`));
            // } else if (Number(value) < Number(shopPrice)) {
            //     callback(new Error(`金额不能小于${shopPrice}`));
            } else {
                callback();
            }
        },
        async deleteFlie(i) { //  删除文件
            let fileList = [...this.fileList];
            fileList = fileList.filter((item, index) => {
                return i != index;
            });
            this.fileList = fileList;
        },
        async uploadFile(e) { // 上传文件
            let fileList = [...this.fileList];
            let file = e.target.files[0];
            let fileName = file.name;
            let format = file.name.split(".")[file.name.split(".").length - 1];
            if (file.size / 1024 > 1024 * 50) {
                e.target.value = "";
                return this.$message.error("文件大小不能超过50M");
            }
            if (!Config.fileType.PWEIType.includes(format)) {
                e.target.value = "";
                return this.$message.error(
                `只能上传 ${Config.fileMessage.PWEIType} 格式的文件`
                );
            }
            this.loading = true;
            let { data, code } = await upload({ file: file });
            this.loading = false;
            if (code == 200) {
                this.$message.success("上传成功");
                fileList.push({
                filedName: fileName,
                filedUrl: data.filename,
                filedId: data.id,
                filedType: Config.fileType.imgType.includes(format)
                    ? "imgText"
                    : "file"
                });
                this.fileList = fileList;
            }
            e.target.value = "";
        },
        launchTask(formName) { // 发起任务-提交
            this.$refs[formName].validate(async (valid) => {
                if (valid) { // 发送请求
                    let ruleFormEdit = JSON.parse(JSON.stringify(this.ruleFormEdit));
                    if (ruleFormEdit.changePriceDTO && ruleFormEdit.changePriceDTO.noticeId) {
                        ruleFormEdit.changePriceDTO.noticeId = ruleFormEdit.changePriceDTO.noticeId.join(",");
                    }
                    if (ruleFormEdit.changePriceDTO && ruleFormEdit.changePriceDTO.shopIds) {
                        ruleFormEdit.changePriceDTO.shopIds = ruleFormEdit.changePriceDTO.shopIds.join(",");
                    } else {
                        delete ruleFormEdit.changePriceDTO.shopIds;
                    }
                    if (ruleFormEdit.changePriceDTO.datePicker && ruleFormEdit.changePriceDTO.datePicker.length) {
                        console.log(DateTransform(ruleFormEdit.changePriceDTO.datePicker[0]),ruleFormEdit.changePriceDTO.datePicker[0], '-----------------')
                        ruleFormEdit.changePriceDTO.startTime = DateTransform(ruleFormEdit.changePriceDTO.datePicker[0]);
                        ruleFormEdit.changePriceDTO.endTime = DateTransform(ruleFormEdit.changePriceDTO.datePicker[1]);
                    }
                    let fileList = [...this.fileList];
                    if (fileList) { // 描述文件
                        let remarkFileName = [];
                        let remarkFileUrl = [];
                        fileList.map((item) => {
                        remarkFileName.push(item.filedName);
                        remarkFileUrl.push(item.filedUrl);
                        });
                        ruleFormEdit.remarkFileName = remarkFileName.join(",");
                        ruleFormEdit.remarkFileUrl = remarkFileUrl.join(",");
                    }
                    if (ruleFormEdit.sendList && ruleFormEdit.sendList.length) {
                        // 抄送人
                        let sendList = [];
                        ruleFormEdit.sendList.map((item) => {
                        sendList.push(item.id);
                        });
                        ruleFormEdit.sendId = sendList.join(",");
                    }
                    if (ruleFormEdit.flowList) {
                        // 任务步骤
                        let isNull = false;
                        ruleFormEdit.flowList.map((item) => {
                        if (item.noticeList && item.noticeList.length) {
                            // 任务步骤-处理人
                            let dealList = [];
                            item.noticeList.map((itemTwo) => {
                            dealList.push(itemTwo.id);
                            });
                            item.dealId = dealList.join(",");
                            delete item.noticeList;
                        } else {
                            isNull = true;
                        }
                        if (item.fileList && item.fileList.length) {
                            // 任务步骤-文件
                            let fileName = [];
                            let fileUrl = [];
                            item.fileList.map((itemTwo) => {
                            fileName.push(itemTwo.filedName);
                            fileUrl.push(itemTwo.filedUrl);
                            });
                            item.fileName = fileName.join(",");
                            item.fileUrl = fileUrl.join(",");
                            delete item.fileList;
                        } else {
                            item.fileName = "";
                            item.fileUrl = "";
                            delete item.fileList;
                        }
                        });
                        if (isNull) {
                        return this.$message.error("审批人不能为空");
                        }
                    }
                    ruleFormEdit.changePriceDTO.orderId = this.orderInfo.id
                    let { code } = await launchTask(ruleFormEdit);
                    if (code == 200) {
                        this.$message.success("提交成功");
                        this.$emit('loadDetail')
                        this.editVisible = false
                    }
                } else {
                    this.$message.error("请填写完整的信息");
                }
            });
        },
        noticeFun() {
            let index = this.editIndex
            let ruleFormEdit = JSON.parse(JSON.stringify(this.ruleFormEdit))
            if (index == "chargeRole") {
                ruleFormEdit.chargeList = [...this.checkedList]
            } else if (index == "sendRole") {
                ruleFormEdit.sendList = [...this.checkedList]
            } else {
                ruleFormEdit.flowList[index].noticeList = [...this.checkedList]
            }
            this.ruleFormEdit = ruleFormEdit;
            this.personVisible = false
        },
        changePerson(val, row) { // 选人员变动
            let checkedList = [...this.checkedList];
            let personData = [...this.personData];
            let index = this.editIndex;
            if (val) {
                if (index == "sendRole") {
                    checkedList.push({ id: row.id, name: row.nickName });
                } else if (index != "sendRole" && !checkedList.length) {
                    checkedList.push({ id: row.id, name: row.nickName });
                } else {
                    personData.map((item) => {
                        item.checked = false;
                        checkedList.map((itemTwo) => {
                            if (item.id == itemTwo.id) {
                                item.checked = true;
                            }
                        });
                    });
                    return this.$message.error("最多只能选择一位成员");
                }
            } else {
                checkedList = checkedList.filter((item) => {
                    return row.id != item.id;
                });
            }
            this.checkedList = checkedList;
        },
        seachFun(val) { // 搜索人员
            let index = this.editIndex;
            let ruleFormEdit = JSON.parse(JSON.stringify(this.ruleFormEdit));
            let params = {
                companyId: this.comId,
                name: val,
                pageNum: 1,
                pageSize: 999999
            };
            if (index == "sendRole") {
                params.roleIds = ruleFormEdit.sendRole;
            } else if (index == "chargeRole") {
                params.roleIds = ruleFormEdit.chargeRole;
            } else {
                params.roleIds = ruleFormEdit.flowList[index].dealRole;
            }
            this.personList(params);
        },
        departTreeFun(val) { //部门选择
            let params = {
                companyId: this.comId,
                roleId: val.id,
                pageNum: 1,
                pageSize: 999999
            };
            this.personList(params);
        },
        async personList(params) { // 获取人员
            let { data } = await personList(params);
            let checkedList = [...this.checkedList];
            if (data.records && data.records.length) {
                data.records.map((item) => {
                item.checked = false;
                checkedList.map((itemTwo) => {
                    if (item.id == itemTwo.id) {
                    item.checked = true;
                    }
                });
                });
            }
            this.personData = data.records || [];
        },
        personShow(ids, i) { // 选择人员
            if (ids) {
                this.getTemplateRole(ids, i);
            } else {
                this.getRoleList(i);
            }
            let noticeList;
            if (i == "chargeRole") {
                noticeList = [...this.ruleFormEdit.chargeList];
            } else if (i == "sendRole") {
                noticeList = [...this.ruleFormEdit.sendList];
            } else {
                noticeList = [...this.ruleFormEdit.flowList[i].noticeList];
            }
            this.editIndex = i;
            this.personData = [];
            this.checkedList = noticeList;
            this.seachInfo = "";
            this.personVisible = true;
        },
        async getRoleList(i) { // 获取模板角色列表
            let { data } = await getRoleList({ pageNum: 1, pageSize: 9999 });
            if (i == "sendRole") {
                data.records = data.records.filter((item) => {
                return item.id != 16;
                });
            }
            this.roleData = data.records;
        },
        async getTemplateRole(ids, i) { // 获取模板角色列表
            let { data } = await getTemplateRole({ ids });
            if (i == "sendRole") {
                data = data.filter((item) => {
                return item.id != 16;
                });
            }
            this.roleData = data;
        },
        changePrice(e) {
            this.ruleFormEdit.changePriceDTO.result = Number(e) + this.ruleFormEdit.changePriceDTO.orderMoneny;
        },
        dateTime(val, type) {
            if (type == "start") {
                this.endPickerOptions = {
                disabledDate: (time) => {
                    return time.getTime() < new Date(val).getTime();
                }
                };
            } else {
                this.startPickerOptions = {
                disabledDate: (time) => {
                    return time.getTime() > new Date(val).getTime();
                }
                };
            }
        },
        async workOrderTemplate(workOrderId) { // 获取工单模板
        let ruleFormEdit = JSON.parse(JSON.stringify(this.ruleFormEdit))
        let { data } = await workOrderTemplate({ workOrderId });
        if (data && data.flowList && data.flowList.length) {
            data.flowList.map((item) => {
                item.fileList = [];
                item.noticeList = [];
                if (item.dealId && item.dealName) {
                    let dealName = item.dealName.split(',')
                    let dealId = item.dealId.split(',')
                    dealName.map((itemTwo, indexTwo) => {
                        item.noticeList.push({ name: itemTwo, id: dealId[indexTwo] })
                    })
                }
                item.status = 0;
            });
        }
        delete data.changePriceDTO
        data.sendList = [];
        if (data.sendId && data.sendName) {
            let sendName = data.sendName.split(',')
            let sendId = data.sendId.split(',')
            sendName.map((item, index) => {
                data.sendList.push({name: item, id: sendId[index]})
            })
        }
        data.chargeList = [];
        if (data.chargeId && data.chargeName) {
            let chargeName = data.chargeName.split(',')
            let chargeId = data.chargeId.split(',')
            chargeName.map((item, index) => {
                data.sendList.push({name: item, id: chargeId[index]})
            })
        }
        this.ruleFormEdit = Object.assign(ruleFormEdit, data)
        if (this.$refs["ruleFormEdit"]) this.$refs["ruleFormEdit"].resetFields();
        },
        async workOrderType() { //类型下拉
            let { data } = await workOrderType({
                workTypeId: 3,
                companyId: this.comId,
                pageSize: 999999,
                pageNum: 1
            });
            this.workOrderSelect = data.list;
        },
        getDataInfo (info) {
            let ruleFormEdit = JSON.parse(JSON.stringify(this.ruleFormEdit))
            ruleFormEdit.changePriceDTO = {
                orderNo: info.orderNo,
                orderMoneny: info.price,
                type: '',
                shopIds: '',
                datePicker: [],
                agreeSubNum: info.agreeSubNum,
                changeMoneny: '',
                result: '',
                remark: ''
            }
            this.startPickerOptions = {}
            this.endPickerOptions = {}
            this.pickerOptionsPrice = {
                disabledDate: (time) => {
                    return time.getTime() < new Date(info.startTime).getTime() || time.getTime() > new Date(info.endTime).getTime()
                }
            }
            this.orderInfo = info
            this.ruleFormEdit = ruleFormEdit
            this.editVisible = true
        }
    }
}
</script>
<style lang="less" scoped>
.changePrice-container {
    text-align: left;
    /deep/.el-radio {
        margin-bottom: 8px;
        font-size: 12px;
        margin-top: 4px;
    }
    .workOrder-process-container {
        margin: 32px;
        padding: 44px 28px;
        box-sizing: border-box;
        border-radius: 4px;
        border: 1px solid #d9d9d9;
        .process-title {
            height: 32px;
            line-height: 32px;
            margin-left: 16px;
        }
        .timelineItem-dot {
            width: 16px;
            height: 16px;
            border-radius: 50%;
            border: 4px solid #eff6ff;
            background: #1890ff;
            color: #eff6ff;
            font-size: 14px;
            line-height: 16px;
            text-align: center;
        }
        /deep/.el-timeline-item__tail {
            left: 11px;
        }
    }
    .grey-line {
        height: 1px;
        background: #eee;
        width: 100%;
    }
    .form-title {
        color: #333;
        font-size: 14px;
        margin: 25px;
    }
    .form-left, .form-right {
        width: 49%;
        display: inline-block;
        vertical-align: top;
    }
    .overtime-container {
        margin-left: 85px;
        border-left: #1890ff solid 4px;
    }
}
</style>