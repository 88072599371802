<template>
    <div class="launchIncome-container">
        <el-dialog class="launchIncome-drawer" title="发起收款" :append-to-body="true" :visible.sync="visibleIncomeDetail" width="560px" :before-close="()=>{closeIncome()}">
        <div class="drawer-container">
            <el-form label-position="left" v-if="IncomeInfo" :model="IncomeInfo" label-width="120px" class="demo-ruleForm">
                <el-form-item class="formItem" label="订单总额：">
                    {{IncomeInfo.orderPrice && Number(IncomeInfo.orderPrice).toFixed(2)}} <span> 元</span>
                </el-form-item>
                <el-form-item class="formItem" label="应收金额：">
                    {{IncomeInfo.uncollectionPrice && Number(IncomeInfo.uncollectionPrice).toFixed(2)}} <span> 元 (未收金额)</span>
                </el-form-item>
                <el-form-item class="formItem" label="订单服务时间：">
                    {{IncomeInfo.orderStartDate}} ~ {{IncomeInfo.orderEndDate}} <span> （{{IncomeInfo.orderDays}}）天</span>
                </el-form-item>
                <el-form-item class="formItem" label="实收金额：">
                    <div>{{IncomeInfo.collectionAmount || 0}} 元</div>
                </el-form-item>
                <el-form-item class="formItem" label="实收对应时间：">
                    <span>{{IncomeInfo.serviceStartTime}} ~ {{IncomeInfo.serviceEndTime}}（{{IncomeInfo.effectiveDays}}）天</span>
                </el-form-item>
                <el-form-item class="formItem" label="收款方式：">
                    <div v-if="IncomeInfo.recordType == 'bankCard'">银行卡</div>
                    <div v-if="IncomeInfo.recordType == 'alipay'">支付宝</div>
                    <div v-if="IncomeInfo.recordType == 'wechat'">微信</div>
                    <div v-if="IncomeInfo.recordType == 'cash'">现金</div>
                    <div v-if="IncomeInfo.recordType == 'other'">其他</div>
                </el-form-item>
                <el-form-item class="formItem" label="通知人：">
                    <div>{{IncomeInfo.noticeNames || '--'}}</div>
                </el-form-item>
                <el-form-item class="formItem" label="备注:">
                    <div>{{IncomeInfo.remark || '--'}}</div>
                </el-form-item>
                <el-form-item class="formItem" label="附件:">
                    <div class="fileList-container" v-if="IncomeInfo.fileUrls && IncomeInfo.fileUrls.length">
                        <div v-for="(item, index) in IncomeInfo.fileUrls" :key="index" class="fileList-item">
                            <div class="item-name">
                                <!-- {{item.fileName}} -->
                                <common-picture :fileUrl="item.fileUrl" :fileType="Config.fileType.imgType.includes(item.fileUrl.split('.')[item.fileUrl.split('.').length - 1]) ? 'imgText' : 'file'" :fileName="item.fileName"></common-picture>
                            </div>
                            <div @click="() => {downLoad(item.fileUrl)}" class="deleteBtn">下载</div>
                        </div>
                    </div>
                    <div v-else>
                        --
                    </div>
                </el-form-item>
                <div class="introducer" v-if="IncomeInfo.invoiceName">
                    <el-form-item class="formItem" label="发票类型：">
                        <div v-if="IncomeInfo.invoiceType == 'common'">增值普票</div>
                        <div v-if="IncomeInfo.invoiceType == 'special'">增值专票</div>
                    </el-form-item>
                    <el-form-item class="formItem" label="名称：">
                        <div>{{IncomeInfo.invoiceName || '--'}}</div>
                    </el-form-item>
                    <el-form-item class="formItem" label="纳税人识别号：">
                        <div>{{IncomeInfo.invoiceTaxpayerIdentificationNumber || '--'}}</div>
                    </el-form-item>
                    <el-form-item class="formItem" label="地址、电话：">
                        <div>{{IncomeInfo.invoicePhone || '--'}}</div>
                    </el-form-item>
                    <el-form-item class="formItem" label="开户行及账号：">
                        <div>{{IncomeInfo.invoiceAccount || '--'}}</div>
                    </el-form-item>
                    <el-form-item class="formItem" label="收件人：">
                        <div>{{IncomeInfo.transactionName || '--'}}</div>
                    </el-form-item>
                    <el-form-item class="formItem" label="收件人手机号：">
                        <div>{{IncomeInfo.transactionPhone || '--'}}</div>
                    </el-form-item>
                    <el-form-item class="formItem" label="收件地址：">
                        <div>{{IncomeInfo.invoiceAddress || '--'}}</div>
                    </el-form-item>
                </div>
            </el-form>
        </div>
    </el-dialog>
    </div>
</template>
<script>
import { Config } from "../../../utils/index.js"
import CommonPicture from "../../../components/common/commonPicture.vue"
export default {
    components: { CommonPicture },
    props: ['visibleIncomeDetail', 'fileList', 'IncomeInfo'],
    data() {
        return {
            Config
        }
    },
    created() {
        console.log(this.IncomeInfo)
    },
    methods: {
        closeIncome() {
            this.$emit('closeIncome', false)
        },
         downLoad(url) { // 下载文件
            window.location.href = url;
        },
    }
}
</script>
<style lang="less" scoped>
/deep/.el-drawer__body {
    padding: 0 !important;
}
.formItem {
    margin: 0;
}
.launchIncome-drawer {
    .introducer {
        position: relative;
        padding: 24px 0 0;
        &::before {
            background-color: #f5f5f5;
            position: absolute;
            display: block;
            content: " ";
            height: 1px;
            width: 120%;
            left: -10%;
            top: 0;
        }
    }
    .drawer-container {
        overflow: hidden;
        padding: 0 14px 0 24px;
        text-align: left;
        .item-YUAN {
            font-size: 14px;
            color: #999;
        }
        .fileList-container {
            .fileList-item {
                display: flex;
                justify-content: space-between;
                width: 320px;
                .item-name {
                font-size: 12px;
                color: #333;
                width: 80%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                }
                .deleteBtn {
                color: #1890ff;
                cursor: pointer;
                }
            }
        }
    }
}
</style>